@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

.channelManager {
    font-family: "Open Sans", sans-serif;
}
.mainCard{
    font-family: "Open Sans", sans-serif;
}
.channelManager .ant-card-body {
    padding-top: 5px;
}

.tabBadge > sup {
    top: -4px;
    right: -12px;
}
.tabContentArea {
    height: 100vh !important;
}
.tabTitle {
    text-transform: uppercase;
    font-weight: bold;
}
.overView .ant-card-head-title {
    padding-left: 5px !important;
}
.overView h1 {
    margin-bottom: 0;
}
.overviewCard {
    padding: 0;
}

.overView .ant-card-body {
    padding: 10px;
}

.contentCard {
    text-align: center;
}
.bestSeller {
    width: 100%;
    color: #fff;
}
.bestSeller h1 {
    color: #fff;
}

.bestSeller.one {
    background: #b67bcf !important;
}
.bestSeller.two {
    background: #548ae0 !important;
}
.bestSeller.three {
    background: #59ca9c !important;
}

.bestSeller .ant-card-head-title {
    padding-left: 0 !important;
}

.bestSeller .ant-card-body {
    padding: 10px;
}

/* List */
.dateCol {
    padding: 5px 0;
    background: #677582;
}
.dateCol.one {
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #fff;
}
.dateCol.two {
    border-radius: 0 0 5px 5px;
}
.contentDec{
    padding-top: 5px;
}
.dateCard {
    background: #677582 !important;
    margin-bottom: 0 !important;
    text-align: center;
    color: #fff;
}
.dateCard h1 {
    color: #fff;
    font-weight: 500;
    margin-bottom: 0;
}
.dateCard h2 {
    color: #fff;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 16px;
}
.dateCard .ant-card-body {
    padding: 0;
}
.contentDec h2{
    color: #83a7d2;
    font-size: 16px;
}
.contentDec p{
    margin-bottom: 5px;
    font-size: 12px;
}
.contentDec h5{
    font-weight: 400;
}
.resPlan{
    padding-top: 5px;
}
.ant-col.ant-col-8.reCard{
    padding-right: 0;
    margin-left: 0;
}
.tablist{
    border-bottom: 1px solid #e8e8e8;
}
.mainCard .ant-card-body{
    padding-top: 5px;
}
.calendarCard .ant-card-body{
    padding-top: 15px;
    padding-bottom: 15px;
}

